/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Mode = "AIRPLANE" | "BICYCLE" | "BUS" | "CABLE_CAR" | "CAR" | "CARPOOL" | "COACH" | "FERRY" | "FLEX" | "FLEXIBLE" | "FUNICULAR" | "GONDOLA" | "LEG_SWITCH" | "RAIL" | "SCOOTER" | "SUBWAY" | "TAXI" | "TRAM" | "TRANSIT" | "WALK" | "%future added value";
export type digitransitSearchUtilQueryUtilsSearchRoutesQueryVariables = {|
  feeds: $ReadOnlyArray<string>,
  name?: ?string,
  modes?: ?$ReadOnlyArray<?Mode>,
|};
export type digitransitSearchUtilQueryUtilsSearchRoutesQueryResponse = {|
  +viewer: ?{|
    +routes: ?$ReadOnlyArray<?{|
      +gtfsId: string,
      +agency: ?{|
        +name: string
      |},
      +type: ?number,
      +shortName: ?string,
      +mode: ?Mode,
      +longName: ?string,
      +patterns: ?$ReadOnlyArray<?{|
        +code: string
      |}>,
    |}>
  |}
|};
export type digitransitSearchUtilQueryUtilsSearchRoutesQuery = {|
  variables: digitransitSearchUtilQueryUtilsSearchRoutesQueryVariables,
  response: digitransitSearchUtilQueryUtilsSearchRoutesQueryResponse,
|};
*/


/*
query digitransitSearchUtilQueryUtilsSearchRoutesQuery(
  $feeds: [String!]!
  $name: String
  $modes: [Mode]
) {
  viewer {
    routes(feeds: $feeds, name: $name, transportModes: $modes) {
      gtfsId
      agency {
        name
        id
      }
      type
      shortName
      mode
      longName
      patterns {
        code
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feeds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "modes"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "kind": "Variable",
    "name": "feeds",
    "variableName": "feeds"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "transportModes",
    "variableName": "modes"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "digitransitSearchUtilQueryUtilsSearchRoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "routes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Agency",
                "kind": "LinkedField",
                "name": "agency",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Pattern",
                "kind": "LinkedField",
                "name": "patterns",
                "plural": true,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "digitransitSearchUtilQueryUtilsSearchRoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "routes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Agency",
                "kind": "LinkedField",
                "name": "agency",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Pattern",
                "kind": "LinkedField",
                "name": "patterns",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1dab72f6203fd4c833a9908177a528ae",
    "id": null,
    "metadata": {},
    "name": "digitransitSearchUtilQueryUtilsSearchRoutesQuery",
    "operationKind": "query",
    "text": "query digitransitSearchUtilQueryUtilsSearchRoutesQuery(\n  $feeds: [String!]!\n  $name: String\n  $modes: [Mode]\n) {\n  viewer {\n    routes(feeds: $feeds, name: $name, transportModes: $modes) {\n      gtfsId\n      agency {\n        name\n        id\n      }\n      type\n      shortName\n      mode\n      longName\n      patterns {\n        code\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '196c6e03fe35f66b3883e4e36529736a';

module.exports = node;
