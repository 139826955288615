/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StopPageHeaderContainer_stop$ref = any;
export type stopRoutes_StopPageHeaderContainer_QueryVariables = {|
  stopId: string
|};
export type stopRoutes_StopPageHeaderContainer_QueryResponse = {|
  +stop: ?{|
    +$fragmentRefs: StopPageHeaderContainer_stop$ref
  |}
|};
export type stopRoutes_StopPageHeaderContainer_Query = {|
  variables: stopRoutes_StopPageHeaderContainer_QueryVariables,
  response: stopRoutes_StopPageHeaderContainer_QueryResponse,
|};
*/


/*
query stopRoutes_StopPageHeaderContainer_Query(
  $stopId: String!
) {
  stop(id: $stopId) {
    ...StopPageHeaderContainer_stop
    id
  }
}

fragment StopCardHeaderContainer_stop on Stop {
  gtfsId
  name
  code
  desc
  zoneId
  alerts {
    alertSeverityLevel
    effectiveEndDate
    effectiveStartDate
    id
  }
  lat
  lon
  stops {
    name
    desc
    id
  }
}

fragment StopPageHeaderContainer_stop on Stop {
  ...StopCardHeaderContainer_stop
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stopId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "stopId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "desc",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_StopPageHeaderContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StopPageHeaderContainer_stop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_StopPageHeaderContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gtfsId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zoneId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "alerts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertSeverityLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveEndDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveStartDate",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Stop",
            "kind": "LinkedField",
            "name": "stops",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c21ca0320b982f7410ae77282e3f5f4",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_StopPageHeaderContainer_Query",
    "operationKind": "query",
    "text": "query stopRoutes_StopPageHeaderContainer_Query(\n  $stopId: String!\n) {\n  stop(id: $stopId) {\n    ...StopPageHeaderContainer_stop\n    id\n  }\n}\n\nfragment StopCardHeaderContainer_stop on Stop {\n  gtfsId\n  name\n  code\n  desc\n  zoneId\n  alerts {\n    alertSeverityLevel\n    effectiveEndDate\n    effectiveStartDate\n    id\n  }\n  lat\n  lon\n  stops {\n    name\n    desc\n    id\n  }\n}\n\nfragment StopPageHeaderContainer_stop on Stop {\n  ...StopCardHeaderContainer_stop\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ba03ad0bd8fe50ebc1267424431e44d';

module.exports = node;
