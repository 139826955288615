/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQueryVariables = {||};
export type digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQueryResponse = {|
  +bikeRentalStations: ?$ReadOnlyArray<?{|
    +name: string,
    +stationId: ?string,
    +lon: ?number,
    +lat: ?number,
  |}>
|};
export type digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQuery = {|
  variables: digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQueryVariables,
  response: digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQueryResponse,
|};
*/


/*
query digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQuery {
  bikeRentalStations {
    name
    stationId
    lon
    lat
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stationId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lon",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BikeRentalStation",
        "kind": "LinkedField",
        "name": "bikeRentalStations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BikeRentalStation",
        "kind": "LinkedField",
        "name": "bikeRentalStations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b1ceb81d1c6e4e5a9c7e76c2e154887f",
    "id": null,
    "metadata": {},
    "name": "digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQuery",
    "operationKind": "query",
    "text": "query digitransitSearchUtilQueryUtilsSearchBikeRentalStationsQuery {\n  bikeRentalStations {\n    name\n    stationId\n    lon\n    lat\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f7214419a92906eeafca80223c41a37';

module.exports = node;
