/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TerminalTimetablePage_station$ref = any;
export type stopRoutes_TerminalPageTimetable_QueryVariables = {|
  terminalId: string,
  date: string,
|};
export type stopRoutes_TerminalPageTimetable_QueryResponse = {|
  +station: ?{|
    +$fragmentRefs: TerminalTimetablePage_station$ref
  |}
|};
export type stopRoutes_TerminalPageTimetable_Query = {|
  variables: stopRoutes_TerminalPageTimetable_QueryVariables,
  response: stopRoutes_TerminalPageTimetable_QueryResponse,
|};
*/


/*
query stopRoutes_TerminalPageTimetable_Query(
  $terminalId: String!
  $date: String!
) {
  station(id: $terminalId) {
    ...TerminalTimetablePage_station_19b1FI
    id
  }
}

fragment TerminalTimetablePage_station_19b1FI on Stop {
  url
  ...TimetableContainer_stop_19b1FI
}

fragment TimetableContainer_stop_19b1FI on Stop {
  gtfsId
  name
  url
  locationType
  stoptimesForServiceDate(date: $date, omitCanceled: false) {
    pattern {
      headsign
      code
      route {
        id
        shortName
        longName
        type
        mode
        agency {
          id
          name
        }
      }
      id
    }
    stoptimes {
      realtimeState
      scheduledDeparture
      serviceDay
      headsign
      pickupType
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "terminalId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "terminalId"
  }
],
v3 = {
  "kind": "Variable",
  "name": "date",
  "variableName": "date"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headsign",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_TerminalPageTimetable_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "TerminalTimetablePage_station"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "stopRoutes_TerminalPageTimetable_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gtfsId",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "omitCanceled",
                "value": false
              }
            ],
            "concreteType": "StoptimesInPattern",
            "kind": "LinkedField",
            "name": "stoptimesForServiceDate",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Pattern",
                "kind": "LinkedField",
                "name": "pattern",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Route",
                    "kind": "LinkedField",
                    "name": "route",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "longName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Agency",
                        "kind": "LinkedField",
                        "name": "agency",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stoptime",
                "kind": "LinkedField",
                "name": "stoptimes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "realtimeState",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledDeparture",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceDay",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pickupType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c59d1fb9ba9d7fb9ee7ce809ba503fbc",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_TerminalPageTimetable_Query",
    "operationKind": "query",
    "text": "query stopRoutes_TerminalPageTimetable_Query(\n  $terminalId: String!\n  $date: String!\n) {\n  station(id: $terminalId) {\n    ...TerminalTimetablePage_station_19b1FI\n    id\n  }\n}\n\nfragment TerminalTimetablePage_station_19b1FI on Stop {\n  url\n  ...TimetableContainer_stop_19b1FI\n}\n\nfragment TimetableContainer_stop_19b1FI on Stop {\n  gtfsId\n  name\n  url\n  locationType\n  stoptimesForServiceDate(date: $date, omitCanceled: false) {\n    pattern {\n      headsign\n      code\n      route {\n        id\n        shortName\n        longName\n        type\n        mode\n        agency {\n          id\n          name\n        }\n      }\n      id\n    }\n    stoptimes {\n      realtimeState\n      scheduledDeparture\n      serviceDay\n      headsign\n      pickupType\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0d4c9d6d38156a6d892f36b2d8743ea';

module.exports = node;
