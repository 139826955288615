/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Mode = "AIRPLANE" | "BICYCLE" | "BUS" | "CABLE_CAR" | "CAR" | "CARPOOL" | "COACH" | "FERRY" | "FLEX" | "FLEXIBLE" | "FUNICULAR" | "GONDOLA" | "LEG_SWITCH" | "RAIL" | "SCOOTER" | "SUBWAY" | "TAXI" | "TRAM" | "TRANSIT" | "WALK" | "%future added value";
export type digitransitSearchUtilQueryUtilsAlertsQueryVariables = {|
  feedIds?: ?$ReadOnlyArray<string>
|};
export type digitransitSearchUtilQueryUtilsAlertsQueryResponse = {|
  +alerts: ?$ReadOnlyArray<?{|
    +route: ?{|
      +mode: ?Mode
    |},
    +effectiveStartDate: ?any,
    +effectiveEndDate: ?any,
  |}>
|};
export type digitransitSearchUtilQueryUtilsAlertsQuery = {|
  variables: digitransitSearchUtilQueryUtilsAlertsQueryVariables,
  response: digitransitSearchUtilQueryUtilsAlertsQueryResponse,
|};
*/


/*
query digitransitSearchUtilQueryUtilsAlertsQuery(
  $feedIds: [String!]
) {
  alerts(severityLevel: [SEVERE], feeds: $feedIds) {
    route {
      mode
      id
    }
    effectiveStartDate
    effectiveEndDate
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "feedIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "feeds",
    "variableName": "feedIds"
  },
  {
    "kind": "Literal",
    "name": "severityLevel",
    "value": [
      "SEVERE"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveStartDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveEndDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "digitransitSearchUtilQueryUtilsAlertsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "route",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "digitransitSearchUtilQueryUtilsAlertsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "route",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1204cb13c5e7e9780e48ff0ea1a98d6c",
    "id": null,
    "metadata": {},
    "name": "digitransitSearchUtilQueryUtilsAlertsQuery",
    "operationKind": "query",
    "text": "query digitransitSearchUtilQueryUtilsAlertsQuery(\n  $feedIds: [String!]\n) {\n  alerts(severityLevel: [SEVERE], feeds: $feedIds) {\n    route {\n      mode\n      id\n    }\n    effectiveStartDate\n    effectiveEndDate\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea0f86e0256b986203a3a723daf8e45b';

module.exports = node;
