/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TerminalPageMeta_station$ref = any;
export type stopRoutes_TerminalPageMeta_QueryVariables = {|
  terminalId: string
|};
export type stopRoutes_TerminalPageMeta_QueryResponse = {|
  +station: ?{|
    +$fragmentRefs: TerminalPageMeta_station$ref
  |}
|};
export type stopRoutes_TerminalPageMeta_Query = {|
  variables: stopRoutes_TerminalPageMeta_QueryVariables,
  response: stopRoutes_TerminalPageMeta_QueryResponse,
|};
*/


/*
query stopRoutes_TerminalPageMeta_Query(
  $terminalId: String!
) {
  station(id: $terminalId) {
    ...TerminalPageMeta_station
    id
  }
}

fragment TerminalPageMeta_station on Stop {
  name
  code
  desc
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "terminalId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_TerminalPageMeta_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TerminalPageMeta_station"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_TerminalPageMeta_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "desc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59dea77ddb4e7d0768c3221348893ca7",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_TerminalPageMeta_Query",
    "operationKind": "query",
    "text": "query stopRoutes_TerminalPageMeta_Query(\n  $terminalId: String!\n) {\n  station(id: $terminalId) {\n    ...TerminalPageMeta_station\n    id\n  }\n}\n\nfragment TerminalPageMeta_station on Stop {\n  name\n  code\n  desc\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fee5b660f713ac6f6ada59a95cc398dd';

module.exports = node;
