/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RoutePageMap_pattern$ref = any;
export type routeRoutes_RoutePageMap_QueryVariables = {|
  patternId: string
|};
export type routeRoutes_RoutePageMap_QueryResponse = {|
  +pattern: ?{|
    +$fragmentRefs: RoutePageMap_pattern$ref
  |}
|};
export type routeRoutes_RoutePageMap_Query = {|
  variables: routeRoutes_RoutePageMap_QueryVariables,
  response: routeRoutes_RoutePageMap_QueryResponse,
|};
*/


/*
query routeRoutes_RoutePageMap_Query(
  $patternId: String!
) {
  pattern(id: $patternId) {
    ...RoutePageMap_pattern
    id
  }
}

fragment RouteLine_pattern on Pattern {
  code
  geometry {
    lat
    lon
  }
  route {
    mode
    type
    color
    id
  }
  stops {
    lat
    lon
    name
    gtfsId
    platformCode
    code
    ...StopCardHeaderContainer_stop
    id
  }
}

fragment RoutePageMap_pattern on Pattern {
  code
  directionId
  headsign
  route {
    type
    mode
    shortName
    id
  }
  geometry {
    lat
    lon
  }
  stops {
    lat
    lon
    name
    gtfsId
    ...StopCardHeaderContainer_stop
    id
  }
  activeDates: trips {
    day: activeDates
    id
  }
  ...RouteLine_pattern
}

fragment StopCardHeaderContainer_stop on Stop {
  gtfsId
  name
  code
  desc
  zoneId
  alerts {
    alertSeverityLevel
    effectiveEndDate
    effectiveStartDate
    id
  }
  lat
  lon
  stops {
    name
    desc
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "patternId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "patternId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "desc",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeRoutes_RoutePageMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Pattern",
        "kind": "LinkedField",
        "name": "pattern",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutePageMap_pattern"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeRoutes_RoutePageMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Pattern",
        "kind": "LinkedField",
        "name": "pattern",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headsign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "route",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortName",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Coordinates",
            "kind": "LinkedField",
            "name": "geometry",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Stop",
            "kind": "LinkedField",
            "name": "stops",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gtfsId",
                "storageKey": null
              },
              (v2/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zoneId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Alert",
                "kind": "LinkedField",
                "name": "alerts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertSeverityLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveStartDate",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stop",
                "kind": "LinkedField",
                "name": "stops",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platformCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "activeDates",
            "args": null,
            "concreteType": "Trip",
            "kind": "LinkedField",
            "name": "trips",
            "plural": true,
            "selections": [
              {
                "alias": "day",
                "args": null,
                "kind": "ScalarField",
                "name": "activeDates",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba96c246b8ac624050d288b2ce79a2a5",
    "id": null,
    "metadata": {},
    "name": "routeRoutes_RoutePageMap_Query",
    "operationKind": "query",
    "text": "query routeRoutes_RoutePageMap_Query(\n  $patternId: String!\n) {\n  pattern(id: $patternId) {\n    ...RoutePageMap_pattern\n    id\n  }\n}\n\nfragment RouteLine_pattern on Pattern {\n  code\n  geometry {\n    lat\n    lon\n  }\n  route {\n    mode\n    type\n    color\n    id\n  }\n  stops {\n    lat\n    lon\n    name\n    gtfsId\n    platformCode\n    code\n    ...StopCardHeaderContainer_stop\n    id\n  }\n}\n\nfragment RoutePageMap_pattern on Pattern {\n  code\n  directionId\n  headsign\n  route {\n    type\n    mode\n    shortName\n    id\n  }\n  geometry {\n    lat\n    lon\n  }\n  stops {\n    lat\n    lon\n    name\n    gtfsId\n    ...StopCardHeaderContainer_stop\n    id\n  }\n  activeDates: trips {\n    day: activeDates\n    id\n  }\n  ...RouteLine_pattern\n}\n\nfragment StopCardHeaderContainer_stop on Stop {\n  gtfsId\n  name\n  code\n  desc\n  zoneId\n  alerts {\n    alertSeverityLevel\n    effectiveEndDate\n    effectiveStartDate\n    id\n  }\n  lat\n  lon\n  stops {\n    name\n    desc\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d6af60b596fa4ca3025008241fa623a';

module.exports = node;
