/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TerminalPageHeaderContainer_station$ref = any;
export type stopRoutes_TerminalPageHeaderContainer_QueryVariables = {|
  terminalId: string
|};
export type stopRoutes_TerminalPageHeaderContainer_QueryResponse = {|
  +station: ?{|
    +$fragmentRefs: TerminalPageHeaderContainer_station$ref
  |}
|};
export type stopRoutes_TerminalPageHeaderContainer_Query = {|
  variables: stopRoutes_TerminalPageHeaderContainer_QueryVariables,
  response: stopRoutes_TerminalPageHeaderContainer_QueryResponse,
|};
*/


/*
query stopRoutes_TerminalPageHeaderContainer_Query(
  $terminalId: String!
) {
  station(id: $terminalId) {
    ...TerminalPageHeaderContainer_station
    id
  }
}

fragment StopCardHeaderContainer_stop on Stop {
  gtfsId
  name
  code
  desc
  zoneId
  alerts {
    alertSeverityLevel
    effectiveEndDate
    effectiveStartDate
    id
  }
  lat
  lon
  stops {
    name
    desc
    id
  }
}

fragment TerminalPageHeaderContainer_station on Stop {
  ...StopCardHeaderContainer_stop
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "terminalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "desc",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_TerminalPageHeaderContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TerminalPageHeaderContainer_station"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_TerminalPageHeaderContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gtfsId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zoneId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "alerts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertSeverityLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveEndDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveStartDate",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Stop",
            "kind": "LinkedField",
            "name": "stops",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c78532b2ca4f4690e308915ef6abba92",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_TerminalPageHeaderContainer_Query",
    "operationKind": "query",
    "text": "query stopRoutes_TerminalPageHeaderContainer_Query(\n  $terminalId: String!\n) {\n  station(id: $terminalId) {\n    ...TerminalPageHeaderContainer_station\n    id\n  }\n}\n\nfragment StopCardHeaderContainer_stop on Stop {\n  gtfsId\n  name\n  code\n  desc\n  zoneId\n  alerts {\n    alertSeverityLevel\n    effectiveEndDate\n    effectiveStartDate\n    id\n  }\n  lat\n  lon\n  stops {\n    name\n    desc\n    id\n  }\n}\n\nfragment TerminalPageHeaderContainer_station on Stop {\n  ...StopCardHeaderContainer_stop\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a21f8735c02355a15715affbb55cf667';

module.exports = node;
