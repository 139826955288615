/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TerminalPageMapContainer_station$ref = any;
export type stopRoutes_TerminalPageMapContainer_QueryVariables = {|
  terminalId: string
|};
export type stopRoutes_TerminalPageMapContainer_QueryResponse = {|
  +station: ?{|
    +$fragmentRefs: TerminalPageMapContainer_station$ref
  |}
|};
export type stopRoutes_TerminalPageMapContainer_Query = {|
  variables: stopRoutes_TerminalPageMapContainer_QueryVariables,
  response: stopRoutes_TerminalPageMapContainer_QueryResponse,
|};
*/


/*
query stopRoutes_TerminalPageMapContainer_Query(
  $terminalId: String!
) {
  station(id: $terminalId) {
    ...TerminalPageMapContainer_station
    id
  }
}

fragment TerminalPageMapContainer_station on Stop {
  lat
  lon
  platformCode
  name
  code
  desc
  vehicleMode
  locationType
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "terminalId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_TerminalPageMapContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TerminalPageMapContainer_station"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_TerminalPageMapContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platformCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "desc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "982b0ac1c568997f1b71589fde1077fd",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_TerminalPageMapContainer_Query",
    "operationKind": "query",
    "text": "query stopRoutes_TerminalPageMapContainer_Query(\n  $terminalId: String!\n) {\n  station(id: $terminalId) {\n    ...TerminalPageMapContainer_station\n    id\n  }\n}\n\nfragment TerminalPageMapContainer_station on Stop {\n  lat\n  lon\n  platformCode\n  name\n  code\n  desc\n  vehicleMode\n  locationType\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7da86716a518e03a57b70b835a4467fe';

module.exports = node;
