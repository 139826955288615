/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Mode = "AIRPLANE" | "BICYCLE" | "BUS" | "CABLE_CAR" | "CAR" | "CARPOOL" | "COACH" | "FERRY" | "FLEX" | "FLEXIBLE" | "FUNICULAR" | "GONDOLA" | "LEG_SWITCH" | "RAIL" | "SCOOTER" | "SUBWAY" | "TAXI" | "TRAM" | "TRANSIT" | "WALK" | "%future added value";
export type digitransitSearchUtilQueryUtilsFavouriteRoutesQueryVariables = {|
  ids: $ReadOnlyArray<string>
|};
export type digitransitSearchUtilQueryUtilsFavouriteRoutesQueryResponse = {|
  +routes: ?$ReadOnlyArray<?{|
    +gtfsId: string,
    +agency: ?{|
      +name: string
    |},
    +shortName: ?string,
    +mode: ?Mode,
    +longName: ?string,
    +patterns: ?$ReadOnlyArray<?{|
      +code: string
    |}>,
  |}>
|};
export type digitransitSearchUtilQueryUtilsFavouriteRoutesQuery = {|
  variables: digitransitSearchUtilQueryUtilsFavouriteRoutesQueryVariables,
  response: digitransitSearchUtilQueryUtilsFavouriteRoutesQueryResponse,
|};
*/


/*
query digitransitSearchUtilQueryUtilsFavouriteRoutesQuery(
  $ids: [String!]!
) {
  routes(ids: $ids) {
    gtfsId
    agency {
      name
      id
    }
    shortName
    mode
    longName
    patterns {
      code
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "digitransitSearchUtilQueryUtilsFavouriteRoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "routes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Agency",
            "kind": "LinkedField",
            "name": "agency",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Pattern",
            "kind": "LinkedField",
            "name": "patterns",
            "plural": true,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "digitransitSearchUtilQueryUtilsFavouriteRoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "routes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Agency",
            "kind": "LinkedField",
            "name": "agency",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Pattern",
            "kind": "LinkedField",
            "name": "patterns",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4005606d21ac741d7bfa516cdac6a056",
    "id": null,
    "metadata": {},
    "name": "digitransitSearchUtilQueryUtilsFavouriteRoutesQuery",
    "operationKind": "query",
    "text": "query digitransitSearchUtilQueryUtilsFavouriteRoutesQuery(\n  $ids: [String!]!\n) {\n  routes(ids: $ids) {\n    gtfsId\n    agency {\n      name\n      id\n    }\n    shortName\n    mode\n    longName\n    patterns {\n      code\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c649ff5e4afa9dee656e7e68d4495b6';

module.exports = node;
