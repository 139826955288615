/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type digitransitSearchUtilQueryUtilsFavouriteStationsQueryVariables = {|
  ids: $ReadOnlyArray<string>
|};
export type digitransitSearchUtilQueryUtilsFavouriteStationsQueryResponse = {|
  +stations: ?$ReadOnlyArray<?{|
    +gtfsId: string,
    +lat: ?number,
    +lon: ?number,
    +name: string,
  |}>
|};
export type digitransitSearchUtilQueryUtilsFavouriteStationsQuery = {|
  variables: digitransitSearchUtilQueryUtilsFavouriteStationsQueryVariables,
  response: digitransitSearchUtilQueryUtilsFavouriteStationsQueryResponse,
|};
*/


/*
query digitransitSearchUtilQueryUtilsFavouriteStationsQuery(
  $ids: [String!]!
) {
  stations(ids: $ids) {
    gtfsId
    lat
    lon
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lon",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "digitransitSearchUtilQueryUtilsFavouriteStationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "digitransitSearchUtilQueryUtilsFavouriteStationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "579b735ab6c1930ac386bc1b0be5eed4",
    "id": null,
    "metadata": {},
    "name": "digitransitSearchUtilQueryUtilsFavouriteStationsQuery",
    "operationKind": "query",
    "text": "query digitransitSearchUtilQueryUtilsFavouriteStationsQuery(\n  $ids: [String!]!\n) {\n  stations(ids: $ids) {\n    gtfsId\n    lat\n    lon\n    name\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40cc9a758603b6db57ff0fb225d9afad';

module.exports = node;
