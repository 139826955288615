/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Mode = "AIRPLANE" | "BICYCLE" | "BUS" | "CABLE_CAR" | "CAR" | "CARPOOL" | "COACH" | "FERRY" | "FLEX" | "FLEXIBLE" | "FUNICULAR" | "GONDOLA" | "LEG_SWITCH" | "RAIL" | "SCOOTER" | "SUBWAY" | "TAXI" | "TRAM" | "TRANSIT" | "WALK" | "%future added value";
export type digitransitSearchUtilQueryUtilsStationsQueryVariables = {|
  ids: $ReadOnlyArray<string>
|};
export type digitransitSearchUtilQueryUtilsStationsQueryResponse = {|
  +stations: ?$ReadOnlyArray<?{|
    +gtfsId: string,
    +lat: ?number,
    +lon: ?number,
    +name: string,
    +code: ?string,
    +stoptimesWithoutPatterns: ?$ReadOnlyArray<?{|
      +trip: ?{|
        +route: {|
          +mode: ?Mode
        |}
      |}
    |}>,
  |}>
|};
export type digitransitSearchUtilQueryUtilsStationsQuery = {|
  variables: digitransitSearchUtilQueryUtilsStationsQueryVariables,
  response: digitransitSearchUtilQueryUtilsStationsQueryResponse,
|};
*/


/*
query digitransitSearchUtilQueryUtilsStationsQuery(
  $ids: [String!]!
) {
  stations(ids: $ids) {
    gtfsId
    lat
    lon
    name
    code
    stoptimesWithoutPatterns(numberOfDepartures: 1) {
      trip {
        route {
          mode
          id
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lon",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "numberOfDepartures",
    "value": 1
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "digitransitSearchUtilQueryUtilsStationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesWithoutPatterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trip",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Route",
                    "kind": "LinkedField",
                    "name": "route",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stoptimesWithoutPatterns(numberOfDepartures:1)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "digitransitSearchUtilQueryUtilsStationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesWithoutPatterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trip",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Route",
                    "kind": "LinkedField",
                    "name": "route",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stoptimesWithoutPatterns(numberOfDepartures:1)"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93ccb0674b3d16bb6fb1dae66373742f",
    "id": null,
    "metadata": {},
    "name": "digitransitSearchUtilQueryUtilsStationsQuery",
    "operationKind": "query",
    "text": "query digitransitSearchUtilQueryUtilsStationsQuery(\n  $ids: [String!]!\n) {\n  stations(ids: $ids) {\n    gtfsId\n    lat\n    lon\n    name\n    code\n    stoptimesWithoutPatterns(numberOfDepartures: 1) {\n      trip {\n        route {\n          mode\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91094e4370d2812b68691efc0d61d94a';

module.exports = node;
