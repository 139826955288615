/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BikeRentalStationPageMapContainer_bikeRentalStation$ref = any;
export type routes_BikeRentalStationMap_QueryVariables = {|
  id: string
|};
export type routes_BikeRentalStationMap_QueryResponse = {|
  +bikeRentalStation: ?{|
    +$fragmentRefs: BikeRentalStationPageMapContainer_bikeRentalStation$ref
  |}
|};
export type routes_BikeRentalStationMap_Query = {|
  variables: routes_BikeRentalStationMap_QueryVariables,
  response: routes_BikeRentalStationMap_QueryResponse,
|};
*/


/*
query routes_BikeRentalStationMap_Query(
  $id: String!
) {
  bikeRentalStation(id: $id) {
    ...BikeRentalStationPageMapContainer_bikeRentalStation
    id
  }
}

fragment BikeRentalStationPageMapContainer_bikeRentalStation on BikeRentalStation {
  lat
  lon
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_BikeRentalStationMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BikeRentalStation",
        "kind": "LinkedField",
        "name": "bikeRentalStation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BikeRentalStationPageMapContainer_bikeRentalStation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_BikeRentalStationMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BikeRentalStation",
        "kind": "LinkedField",
        "name": "bikeRentalStation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "557b5ebec03f8c9d4b11806ddc74be41",
    "id": null,
    "metadata": {},
    "name": "routes_BikeRentalStationMap_Query",
    "operationKind": "query",
    "text": "query routes_BikeRentalStationMap_Query(\n  $id: String!\n) {\n  bikeRentalStation(id: $id) {\n    ...BikeRentalStationPageMapContainer_bikeRentalStation\n    id\n  }\n}\n\nfragment BikeRentalStationPageMapContainer_bikeRentalStation on BikeRentalStation {\n  lat\n  lon\n  name\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b2d797112742d3bee7d777087f65414';

module.exports = node;
